import axiosInstance from '../../../helpers/backend_helper';

const createAmazonBook = async (title, description, amazonLink, coverImage) => {
  try {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('coverImage', coverImage);  // Cover image file
    formData.append('amazonLink', amazonLink);  // Amazon book link

    const response = await axiosInstance.post('/api/v1/books/createAmazonBook', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error creating Amazon book:', error);
    throw error;
  }
};

export default createAmazonBook;
