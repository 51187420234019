import axiosInstance from '../../../helpers/backend_helper';

const updateAbout = async (content, aboutMeImages, aboutMeImageOne, existingData) => {
  try {
    const formData = new FormData();

    // Append the content
    formData.append('content', content || existingData.content);

    // Append aboutMeImages as a single file if available.
    if (aboutMeImages) {
      formData.append('aboutMeImages', aboutMeImages);
    }

    // Append aboutMeImageOne, if provided
    if (aboutMeImageOne) {
      formData.append('aboutMeImageOne', aboutMeImageOne);
    }

    const response = await axiosInstance.post('/api/v1/abouts', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    console.log("Response from server:", response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating about:', error);
    throw error;
  }
};

export default updateAbout;
