import axiosInstance from '../../../helpers/backend_helper';

// Function to create an episode associated with a specific book
const createEpisode = async (bookId, title, story, description, coverImage, audioLink, videoLinkString) => {
  try {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('story', story);
    formData.append('description', description);
    formData.append('coverImage', coverImage); // Cover image file
    formData.append('audioLink', audioLink);   // Audio link file
    formData.append('videoLinkString', videoLinkString); // Video link string

    const response = await axiosInstance.post(`/api/v1/books/${bookId}/episodes`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error creating episode:', error);
    throw error;
  }
};

export default createEpisode;
