import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
  Spinner,
  Alert
} from "reactstrap";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import updateAmazonBook from "common/realBackend/blog/updateAmazonBook"; // Function to update Amazon book details
import getSingleAmazonBook from "common/realBackend/blog/getSingleAmazonBook"; // Function to fetch Amazon book details by ID
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const UpdateAmazonBooks = ({ onUpdateSuccess }) => {
  const { bookId } = useParams(); // Get book ID from params
  const [existingBook, setExistingBook] = useState(null);
  const [coverImagePreview, setCoverImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  // Validation schema
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Book Title is required"),
    description: Yup.string().required("Book Description is required"),
    amazonLink: Yup.string().url("Enter a valid URL").required("Amazon Link is required"),
    coverImage: Yup.mixed().nullable(),
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const bookData = await getSingleAmazonBook(bookId);
        const bookDetails = bookData.data.amazonBook;
        setExistingBook(bookDetails);
        if (bookDetails.coverImage) {
          setCoverImagePreview(bookDetails.coverImage);
        }
      } catch (error) {
        console.error("Error fetching book data:", error);
      }
    };
    fetchData();
  }, [bookId]);

  const handleSubmit = async (values) => {
    const { title, description, amazonLink, coverImage } = values;

    setLoading(true);
    try {
      await updateAmazonBook(bookId, title, description, amazonLink, coverImage);

      setAlertMessage("Updated successfully!");
      setAlertVisible(true);
      if (onUpdateSuccess) onUpdateSuccess();
    } catch (error) {
      setAlertMessage("Error updating. Please try again.");
      setAlertVisible(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Books" breadcrumbItem="Update Amazon Book" />

        {alertVisible && (
          <Alert color={alertMessage.includes("Error") ? "danger" : "success"}
                 toggle={() => setAlertVisible(false)}
                 style={{ position: 'fixed', top: '1rem', right: '1rem', zIndex: 1050 }}>
            {alertMessage}
          </Alert>
        )}

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Update Amazon Book</CardTitle>
                {existingBook ? (
                  <Formik
                    initialValues={{
                      title: existingBook.title || "",
                      description: existingBook.description || "",
                      amazonLink: existingBook.amazonLink || "",
                      coverImage: null,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                  >
                    {({ setFieldValue }) => (
                      <Form>
                        <FormGroup className="mb-4">
                          <Label htmlFor="title" className="col-form-label">Book Title</Label>
                          <Field id="title" name="title" as={Input} placeholder="Enter Title..." />
                          <ErrorMessage name="title" component="div" className="text-danger" />
                        </FormGroup>

                        <FormGroup className="mb-4">
                          <Label className="col-form-label">Book Description</Label>
                          <Field
                            component="textarea"
                            name="description"
                            className="form-control"
                            rows="5"
                            placeholder="Enter description..."
                          />
                          <ErrorMessage name="description" component="div" className="text-danger" />
                        </FormGroup>

                        <FormGroup className="mb-4">
                          <Label htmlFor="amazonLink" className="col-form-label">Amazon Link</Label>
                          <Field id="amazonLink" name="amazonLink" as={Input} placeholder="Enter Amazon Link..." />
                          <ErrorMessage name="amazonLink" component="div" className="text-danger" />
                        </FormGroup>

                        <FormGroup className="mb-4">
                          <Label className="col-form-label">Cover Image</Label>
                          <Input
                            type="file"
                            className="form-control"
                            onChange={(e) => {
                              const file = e.currentTarget.files[0];
                              setFieldValue("coverImage", file);
                              setCoverImagePreview(URL.createObjectURL(file));
                            }}
                          />
                          {coverImagePreview && (
                            <div className="mt-2">
                              <img src={coverImagePreview} alt="Cover Preview" style={{ maxWidth: '250px', height: 'auto' }} />
                            </div>
                          )}
                          <ErrorMessage name="coverImage" component="div" className="text-danger" />
                        </FormGroup>

                        <Row className="justify-content-end">
                          <Col lg="10">
                            <Button type="submit" color="primary" disabled={loading}>
                              {loading ? <Spinner size="sm" /> : "Update Book"}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <Spinner color="primary" />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UpdateAmazonBooks;
