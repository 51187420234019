import axiosInstance from '../../../helpers/backend_helper';

const updateAmazonBook = async (id, title, description, amazonLink, coverImage) => {
  try {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('amazonLink', amazonLink);
    if (coverImage) { // Only append if there's a new cover image
      formData.append('coverImage', coverImage); // Cover image file
    }

    const response = await axiosInstance.patch(`/api/v1/books/updateAmazonBook/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error updating Amazon book:', error);
    throw error;
  }
};

export default updateAmazonBook;
