import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
  Spinner,
  Alert
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import createAmazonBook from "common/realBackend/blog/createAmazonBook"; // Adjust import based on your project structure
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const BookCreate = () => {
  // Page title setting
  document.title = "Create Amazon Book | AAOOA Admin";

  const [coverImagePreview, setCoverImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  // Form validation schema using Yup
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Book Title is required"),
    description: Yup.string().required("Book Description is required"),
    coverImage: Yup.mixed().required("Cover Image is required"),
    amazonLink: Yup.string().url("Invalid URL format").required("Amazon Book Link is required"),
  });

  // Handle form submission
  const handleSubmit = async (values, { resetForm }) => {
    const { title, description, coverImage, amazonLink } = values;

    setLoading(true);
    try {

        // Log the data being sent to the API
        console.log('Submitting data to API:', {
            title,
            description,
            amazonLink,
            coverImage,
        });

      // Call the function to create the Amazon book
      await createAmazonBook(title, description, amazonLink, coverImage);
      setAlertMessage("Amazon Book created successfully!");
      setAlertVisible(true);

      // Reset form and preview after successful submission
      resetForm();
      setCoverImagePreview(null);
    } catch (error) {
      console.error("Error creating Amazon book:", error);
      setAlertMessage("Error creating book. Please try again.");
      setAlertVisible(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Books" breadcrumbItem="Create Amazon Book" />

        {/* Alert for success or error */}
        {alertVisible && (
          <Alert
            color={alertMessage.includes("Error") ? "danger" : "success"}
            toggle={() => setAlertVisible(false)}
            style={{ position: "fixed", top: "1rem", right: "1rem", zIndex: 1050 }}
          >
            {alertMessage}
          </Alert>
        )}

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Create New Amazon Book</CardTitle>
                <Formik
                  initialValues={{
                    title: "",
                    description: "",
                    coverImage: null,
                    amazonLink: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ setFieldValue, resetForm }) => (
                    <Form>
                      <FormGroup className="mb-4">
                        <Label htmlFor="title" className="col-form-label">Book Title</Label>
                        <Field
                          id="title"
                          name="title"
                          as={Input}
                          placeholder="Enter Book Title..."
                        />
                        <ErrorMessage name="title" component="div" className="text-danger" />
                      </FormGroup>

                      <FormGroup className="mb-4">
                        <Label htmlFor="description" className="col-form-label">Book Description</Label>
                        <Field name="description">
                          {({ field }) => (
                            <textarea
                              {...field}
                              id="description"
                              className="form-control"
                              placeholder="Enter Book Description..."
                              rows="6"
                            />
                          )}
                        </Field>
                        <ErrorMessage name="description" component="div" className="text-danger" />
                      </FormGroup>

                      <FormGroup className="mb-4">
                        <Label className="col-form-label">Cover Image</Label>
                        <Input
                          type="file"
                          className="form-control"
                          onChange={(e) => {
                            const file = e.currentTarget.files[0];
                            setFieldValue("coverImage", file);
                            setCoverImagePreview(URL.createObjectURL(file));
                          }}
                        />
                        {coverImagePreview && (
                          <div className="mt-2">
                            <img src={coverImagePreview} alt="Cover Preview" style={{ maxWidth: "250px", height: "auto" }} />
                          </div>
                        )}
                        <ErrorMessage name="coverImage" component="div" className="text-danger" />
                      </FormGroup>

                      <FormGroup className="mb-4">
                        <Label htmlFor="amazonLink" className="col-form-label">Amazon Book Link</Label>
                        <Field
                          id="amazonLink"
                          name="amazonLink"
                          as={Input}
                          placeholder="Enter Amazon Book Link..."
                        />
                        <ErrorMessage name="amazonLink" component="div" className="text-danger" />
                      </FormGroup>

                      <Row className="justify-content-end">
                        <Col lg="10">
                          <Button type="submit" color="primary" disabled={loading}>
                            {loading ? <Spinner size="sm" /> : "Create Amazon Book"}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BookCreate;
