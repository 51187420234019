import React, { useState, useEffect } from "react";
import { Card, Col, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import getAllBooks from "common/realBackend/blog/getAllBooks";
import deleteBookById from "common/realBackend/blog/deleteBook"; // Import the deleteBook function
import classnames from "classnames";

const BookGrid = () => {
  const [activeTab, toggleTab] = useState("1");
  const [books, setBooks] = useState([]);
  const [filteredBooks, setFilteredBooks] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [bookToDelete, setBookToDelete] = useState(null); // To store the book ID to be deleted
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const response = await getAllBooks();
        if (response.status === "success") {
          setBooks(response.data.books);
          setFilteredBooks(response.data.books);
        }
      } catch (error) {
        console.error("Error fetching books:", error);
      }
    };

    fetchBooks();
  }, []);

  useEffect(() => {
    let filtered = books;
    if (activeTab === "1") {
      filtered = filtered.filter((book) => book.type === "one-short");
    } else if (activeTab === "2") {
      filtered = filtered.filter((book) => book.type === "series");
    }
    if (searchQuery) {
      filtered = filtered.filter((book) =>
        book.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    setFilteredBooks(filtered);
  }, [searchQuery, books, activeTab]);

  const handleView = (bookId, bookType) => {
    if (bookType === "series") {
      navigate(`/blog-episodes/${bookId}`);
    } else {
      navigate(`/blog-details/one-shot/${bookId}`);
    }
  };

  const handleUpdate = (bookId) => {
    navigate(`/story-update/one-shot/${bookId}`);
  };

  const handleDelete = (bookId) => {
    setBookToDelete(bookId); // Store the book ID in state
    setModalOpen(true); // Open the modal
  };

  const confirmDelete = async () => {
    try {
      if (bookToDelete) {
        await deleteBookById(bookToDelete); // Call the delete function
        setBooks(books.filter((book) => book.id !== bookToDelete)); // Update the book list by removing the deleted book
        setFilteredBooks(filteredBooks.filter((book) => book.id !== bookToDelete)); // Update filtered books
      }
    } catch (error) {
      console.error("Error deleting book:", error);
    }
    setModalOpen(false); // Close the modal after confirmation
  };

  const closeModal = () => {
    setModalOpen(false); // Close the modal if the user cancels
  };

  return (
    <React.Fragment>
      <Col xl={9} lg={8}>
        <Card>
          <ul className="nav nav-tabs nav-tabs-custom justify-content-center pt-2" role="tablist">
            <li className="nav-item">
              <a
                className={classnames({ active: activeTab === "1" }, "nav-link")}
                onClick={() => toggleTab("1")}
                href="#"
              >
                One-Shot
              </a>
            </li>
            <li className="nav-item">
              <a
                className={classnames({ active: activeTab === "2" }, "nav-link")}
                onClick={() => toggleTab("2")}
                href="#"
              >
                Series
              </a>
            </li>
          </ul>

          <div className="search-box mb-4 p-3">
            <input
              type="text"
              className="form-control rounded bg-light border-light"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          <Table responsive>
            <thead>
              <tr>
                <th>Image</th>
                <th>Title</th>
                <th>Created At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredBooks.map((book) => (
                <tr key={book.id}>
                  <td>
                    <img
                      src={book.coverImage}
                      alt={book.title}
                      style={{ width: "75px", height: "50px", objectFit: "cover" }}
                    />
                  </td>
                  <td>
                    <Link to={`/book-details/${book.id}`} className="text-dark">
                      {book.title}
                    </Link>
                  </td>
                  <td>{new Date(book.createdAt).toLocaleDateString()}</td>
                  <td>
                    <Button
                      color="link"
                      className="text-primary"
                      onClick={() => handleView(book.id, book.type)}
                      title="View"
                    >
                      <i className="mdi mdi-eye font-size-16 text-primary me-1" />
                    </Button>
                    <Button
                      color="link"
                      className="text-warning me-2"
                      onClick={() => handleUpdate(book.id)}
                      title="Update"
                    >
                      <i className="mdi mdi-pencil font-size-16 text-warning me-1" />
                    </Button>
                    <Button
                      color="link"
                      className="text-danger"
                      onClick={() => handleDelete(book.id)}
                      title="Delete"
                    >
                      <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>

      {/* Confirmation Modal */}
      <Modal isOpen={modalOpen} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>Confirm Deletion</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this book? This action cannot be undone.
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button color="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default BookGrid;
