// src/BookDetails.tsx
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Card, CardBody, Col, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import img1 from "../../assets/images/small/img-2.jpg";
import getBookById from "common/realBackend/blog/getBookById";
import getEpisodeById from "common/realBackend/blog/getEpisodeById";

const BookDetails = () => {
  document.title = "Book Details | AAOOA Admin";
  const [content, setContent] = useState(null);
  const { type, id, episodeId } = useParams();

  // Log useParams values to verify
  console.log("type:", type, "id:", id, "episodeId:", episodeId);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        if (type === "one-shot") {
          const data = await getBookById(id);
          console.log("Fetched Book Data (One-Shot):", data); // Log the fetched data
          setContent(data.data.book);
        } else if (type === "series") {
          if (episodeId) {
            const episodeData = await getEpisodeById(id, episodeId);
            console.log("Fetched Episode Data:", episodeData); // Log the episode data
            setContent(episodeData.data.episode || episodeData.book);
          } else {
            const data = await getBookById(id);
            console.log("Fetched Book Data (Series):", data); // Log the fetched data
            setContent(data.book);
          }
        }
      } catch (error) {
        console.error(`Error fetching ${type} with ID ${id}${episodeId ? ` and episode ID ${episodeId}` : ""}:`, error);
      }
    };
    fetchContent();
  }, [type, id, episodeId]);

  if (!content) {
    return <p>Loading...</p>;
  }

  const extractYouTubeID = (url) => {
    if (!url) return null; // Return null if the URL is falsy (null, undefined, empty string)
    
    const match = url.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/) ||
                  url.match(/(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^&]+)/);
    return match ? match[1] : null;
  };  

  const youtubeID = extractYouTubeID(content.videoLinkString);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={type === "one-shot" ? "One-Shot" : "Series"} breadcrumbItem="Details" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="pt-3">
                    <div className="row justify-content-center">
                      <div className="col-xl-8">
                        <div className="text-center">
                          <h4>{content.title}</h4>
                          <p className="text-muted mb-4">
                            <i className="mdi mdi-calendar me-1"></i> {new Date(content.createdAt).toLocaleDateString()}
                          </p>
                        </div>

                        <hr />

                        <div className="my-5">
                          <img
                            src={content.coverImage || img1}
                            alt="Cover"
                            className="img-thumbnail mx-auto d-block"
                          />
                        </div>

                        <hr />

                        <div className="mt-4">
                          <div className="text-muted font-size-14">
                            {/* Render description with dangerouslySetInnerHTML */}
                            <p 
                              style={{ lineHeight: "2" }} 
                              dangerouslySetInnerHTML={{ __html: content.description }}
                            />
                          </div>

                          {content.audioLink && (
                            <div className="mt-4">
                              <audio controls>
                                <source src={content.audioLink} type="audio/mpeg" />
                                Your browser does not support the audio element.
                              </audio>
                            </div>
                          )}

                          {youtubeID && (
                            <div className="mt-4">
                              <iframe
                                width="300" // Adjust width as needed
                                height="200" // Adjust height as needed
                                src={`https://www.youtube.com/embed/${youtubeID}`}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                              ></iframe>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BookDetails;
